<template>
  <v-app-bar
    app
    clipped-left
    color="background"
    :height="onBoardingCompleted ? 62 : 104"
    elevation="0"
  >
    <v-container fluid>
      <div
        v-if="!onBoardingCompleted"
        class="text-center d-flex align-center justify-center warning onboarding-warning"
        id="complete-onboarding"
        @click="openOnboarding"
      >
        <v-icon color="black">mdi-alert-outline</v-icon>
          <span class="black--text mx-5 mobile-text">{{ $t('onboarding.warning') }}</span>
        <v-icon color="black">mdi-chevron-right</v-icon>
      </div>
      <v-row justify="center" align="center" :class="!onBoardingCompleted && 'mt-8'">
        <v-img
          :src="logoUrl"
          :height="31"
          :max-width="150"
          class="ml-sm-9 cursor-pointer"
          @click="$router.push('/portfolio')"
        />
        <v-spacer></v-spacer>
        <v-btn
          icon
          :to="{ name: 'Profile' }"
          id='profile-icon'
        >
          <Profile :color="$vuetify.theme.dark ? '#ffffff' : '#000000'" />
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Profile from '@/assets/icons/svg/Profile.vue';

export default {
  name: 'ApplicationBar',
  components: {
    Profile,
  },
  computed: {
    ...mapGetters('application', [
      'pageTitle',
    ]),
    ...mapGetters('user', [
      'onBoardingCompleted',
    ]),
    logoUrl() {
      const mode = this.$vuetify.theme.dark ? 'dark' : 'light';
      return require(`@/assets/logos/cometum/vertical-primary-${mode}.svg`);
    },
  },
  methods: {
    openOnboarding() {
      this.$router.push({
        name: 'Onboarding.Overview',
      });
    },
  },
};
</script>

<style scoped>
.pageTitle {
  padding-left: 0.5em;
}
.onboarding-warning {
  width: 100vw;
  height: 42px;
  margin-left: -27px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
@media (max-width: 600px) {
  .mobile-text {
    font-size: 10px;
  }
  #profile-icon {
    margin-right: -10px;
  }
}
</style>
